$global-link-color: #FF4C18;
$global-primary-background: #FF4C18;
$global-link-color: #FF4C18;
$global-danger-background: #c42e00;
$global-background: #fff;
$global-muted-background: #fefefe;
@import "~uikit/src/scss/variables";
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";

@import "~uikit/src/scss/uikit-theme.scss";
//@import "~uikit/dist/css/uikit.min.css";

@import url('https://fonts.googleapis.com/css2?family=Rakkas&family=Roboto:wght@300&display=swap');

:root {
  --primary: #{$global-primary-background};
  --light-primary: #{lighten($global-primary-background, 20%)};
}

* {
  font-family: 'Roboto', sans-serif;
}

html, body {
  height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

input, textarea {
  color: #1c1c1c !important;
}

$placeholder-color: #{lighten($global-primary-background, 20%)};
::placeholder {
  color: $placeholder-color !important;
  opacity: 1 !important; /* Firefox */
}

:-ms-input-placeholder:not(input[type=file]) { /* Internet Explorer 10-11 */
  color: $placeholder-color !important;
}

::-ms-input-placeholder:not(input[type=file]) { /* Microsoft Edge */
  color: $placeholder-color !important;
}

.content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

h1, h2, h3 {
  font-family: 'Rakkas', cursive;
}

.uk-card-danger {
  background-color: $button-danger-background;
  color: $button-danger-color;
}


.uk-text-white {
  color: #EFEFEF
}

.uk-logo img {
  height: 4rem;
}

.uk-navbar-nav > li > a,
.uk-navbar-nav > li > span {
  text-transform: none !important;
}

/* ------------------------------------------------------------------------------------------------------------------ */
/**
 https://codepen.io/zzseba78/pen/PxwmeV
 Switch
*/
$switch-size: 1rem;
.uk-switch {
  position: relative;
  display: inline-block;
  height: $switch-size * 1.5;
  width: 100%;
}

/* Hide default HTML checkbox */
.uk-switch input {
  display: none;
}

.uk-switch .uk-switch-label {
  margin-left: $switch-size * 4;
}

/* Slider */
.uk-switch-slider {
  width: $switch-size * 3;
  background-color: rgba(0, 0, 0, 0.22);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 500px;
  bottom: 0;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: .2s;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.07);
}

/* Switch pointer */
.uk-switch-slider:before {
  content: '';
  background-color: #fff;
  position: absolute;
  width: $switch-size;
  height: $switch-size;
  left: $switch-size / 4;
  bottom: $switch-size / 4;
  border-radius: 50%;
  transition-property: transform, box-shadow;
  transition-duration: .2s;
}

/* Slider active color */
input:checked + .uk-switch-slider {
  background-color: $global-primary-background !important;
}

/* Pointer active animation */
input:checked + .uk-switch-slider:before {
  transform: translateX($switch-size * 1.5);
}

/* Modifiers */
.uk-switch-slider.uk-switch-on-off {
  background-color: #f0506e;
}

input:checked + .uk-switch-slider.uk-switch-on-off {
  background-color: #32d296 !important;
}

/* Style Modifier */
.uk-switch-slider.uk-switch-big:before {
  transform: scale(1.2);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

.uk-switch-slider.uk-switch-small:before {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

input:checked + .uk-switch-slider.uk-switch-big:before {
  transform: translateX($switch-size * 1.5) scale(1.2);
}

/* Inverse Modifier - affects only default */
.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
  background-color: rgba(255, 255, 255, 0.22);
}

/*
 END Switch
*/
/* ------------------------------------------------------------------------------------------------------------------ */
/**
 Image placeholder
 */
.no-height {
  height: 0 !important;
}

.invisible {
  visibility: hidden;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 100px;
  position: relative;
}

/**
 END Image placeholder
 */
/* ------------------------------------------------------------------------------------------------------------------ */